import { RefObject, useEffect } from 'react';

/**
 * A hook to detect clicks outside of a given element and trigger a callback function.
 *
 * @param {RefObject<HTMLElement> | HTMLElement | null} ref - The reference to the HTML element.
 * @param {(event: MouseEvent | TouchEvent) => void} callback - The callback function. Must include e.stopPropagation()
 * @param {string} [excludeClassName] - A class name to exclude triggering the outside click event.
 *
 * @example
 * const ref = useRef<HTMLElement | null>(null);
 * useOutsideClick(ref, () => console.log('Outside clicked!'));
 */
const useClickOutside = (
	ref: RefObject<HTMLElement> | HTMLElement | null,
	callback: (event: MouseEvent | TouchEvent) => void,
	excludeClassName?: string,
) => {
	useEffect(() => {
		const listener = (event: MouseEvent | TouchEvent) => {
			if (!ref) return;

			const refObj = ref instanceof HTMLElement ? ref : ref.current;

			if (
				!refObj ||
				refObj.contains(event.target as Node) ||
				(excludeClassName &&
					((event.target as Element).classList.contains(excludeClassName) ||
						(event.target as Element).closest(`.${excludeClassName}`)))
			) {
				return;
			}

			callback(event);
		};

		window.addEventListener('click', listener);

		return () => {
			window.removeEventListener('click', listener);
		};
	}, [ref, callback, excludeClassName]); // Added `excludeClassName` to the dependency array
};

export default useClickOutside;
