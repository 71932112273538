import { createContext, ReactNode, useContext, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { generateUUID } from '../lib/functions';
import Toast from '../components/disclosure/toast';

const ToastContext = createContext<{
	createToast: (text: string, options?: { description?: string }) => void;
}>({
	createToast: () => null,
});

export const ToastProvider = (props: { children: ReactNode }) => {
	const [isHovered, setIsHovered] = useState<boolean>(false);
	const [toasts, setToasts] = useState<
		Array<{
			text: string;
			description?: string;
			id: string;
		}>
	>([]);
	const createToast = (
		text: string,
		options?: {
			description?: string;
		},
	) => {
		const toast = {
			text,
			description: options?.description,
			id: generateUUID(),
		};

		setToasts((prev) => [...prev, toast]);
		setTimeout(() => {
			setToasts((prev) => prev.filter((t) => t.id !== toast.id));
		}, 3000);
		return;
	};

	const handleMouseOver = () => {
		setIsHovered(true);
	};

	const handleMouseOut = () => {
		setIsHovered(false);
	};

	return (
		<ToastContext.Provider value={{ createToast }}>
			<AnimatePresence>
				{toasts.length > 0 && (
					<motion.div>
						{toasts.map((toast, index) => (
							<Toast
								key={index}
								index={index}
								scaleDownFactor={toasts.length - (index + 1)}
								{...toast}
							/>
						))}
					</motion.div>
				)}
			</AnimatePresence>
			{props.children}
		</ToastContext.Provider>
	);
};

export const useToast = () => {
	const { createToast } = useContext(ToastContext);

	return { toast: createToast };
};
