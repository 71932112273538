import { SupabaseClient } from '@supabase/supabase-js';
import { _SnackTodo } from '../../../lib/core/types';
import { ITodo } from './todo';

interface ITodoBackend {
	todos: ITodo[];
	engine: TodoBackendEngine;
	getAll: (user_id: string) => Promise<_SnackTodo>;
	create: (todo: _SnackTodo, user_id: string) => void;
	update: (todo: _SnackTodo) => Promise<_SnackTodo>;
	delete: (id: string) => Promise<void>;
}

interface TodoBackendEngine {
	instance: SupabaseClient;
	table_name: string;
}

export class TodoEngine implements TodoBackendEngine {
	table_name: string;

	constructor(
		public instance: SupabaseClient,
		table: string,
	) {
		this.table_name = table;
	}
}

interface TodoRealtimeEngine {
	engine: TodoBackendEngine;
	subscribe: () => Promise<void>;
	emit: () => Promise<void>;
}

export class TodoRealtime implements TodoRealtimeEngine {
	constructor(public engine: TodoBackendEngine) {}

	subscribe = async () => {};

	emit = async () => {};
}

export default class TodoBackend implements ITodoBackend {
	todos: ITodo[];
	constructor(public engine: TodoBackendEngine) {}

	setTodos = (todos: Array<ITodo | _SnackTodo>) => {
		console.log('setting todos: ', todos);
		this.todos = todos as Array<ITodo>;
	};

	getAll = (user_id: string): Promise<_SnackTodo> => {
		return;
	};

	create = async (todo: _SnackTodo) => {
		const item = {
			title: todo.title,
			description: todo.description,
			_when: todo._when,
			is_complete: todo.is_complete,
			user_id: todo.user_id,
		};
		await this.engine.instance.from(this.engine.table_name).insert(item);
	};

	update = (todo: _SnackTodo): Promise<_SnackTodo> => {
		return;
	};

	delete = (id: string): Promise<void> => {
		return;
	};

	subscribeToAll = async (user_id: string, cb: (data) => void) => {};
}
