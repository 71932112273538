import React from 'react';

type KbdProps = {
	keys: Array<string | Keys>;
};

export const metaKey = {
	win: 'Ctrl',
	mac: '⌘',
};

export enum Keys {
	Meta,
	Backspace = '⌫',
	Enter = '⏎',
	Tab = '⇥',
	ArrowLeft = '←',
	ArrowRight = '→',
	ArrowUp = '↑',
	ArrowDown = '↓',
	Shift = '⇧',
	Alt = '⌥',
}

const Kbd = (props: KbdProps) => {
	return (
		<span className="text-surface-9">
			{props.keys.map((key, index) => (
				<>
					<kbd
						key={index}
						className="px-1.5 py-0.5 text-sm font-medium font-mono bg-zinc-950/5 dark:bg-surface-11 dark:text-surface-2 uppercase rounded inline-flex ml-1 w-fit gap-2">
						<span>
							{key === 'Backspace'
								? Keys.Backspace
								: key === Keys.Meta
								  ? metaKey.mac
								  : key}
						</span>
					</kbd>
				</>
			))}
		</span>
	);
};

export default Kbd;
