import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
} from '../../../../components/ui/select';
import { cn } from '../../../../lib/functions/utils';
import { iconColors } from '../../../../constants/style';
import useColor from '../../../../lib/hooks/useColor';

export type SnackColor = keyof typeof iconColors;

type ColorProps = {
	value: SnackColor;
	onChange: (val: string) => void;
};

const Color = ({ value, onChange }: ColorProps) => {
	const color = useColor(iconColors[value]);

	return (
		<Select
			onValueChange={onChange}
			defaultValue={'primary'}>
			<SelectTrigger
				className={cn(
					'p-1 rounded-lg hover:scale-110 bg-blend-screen transition-transform origin-center',
				)}
				noarrow>
				<p
					className={'font-medium w-8 h-6 rounded-lg border-2'}
					style={{ background: color, borderColor: `${color}30` }}
				/>
			</SelectTrigger>
			<SelectContent className="grid grid-cols-4 gap-2 w-64">
				<SelectItem
					noCheck
					value={'primary'}>
					<div className="flex items-center gap-4">
						<p
							className="font-semibold w-8 h-6 rounded-lg"
							style={{ background: iconColors.primary }}
						/>
						<span className="font-semibold text-surface-11 dark:text-surface-6">
							Space Blue
						</span>
					</div>
				</SelectItem>
				<SelectItem
					noCheck
					value={'accent'}>
					<div className="flex items-center gap-4">
						<p
							className="font-semibold w-8 h-6 rounded-lg"
							style={{ background: iconColors.accent }}
						/>
						<span className="font-semibold text-surface-11 dark:text-surface-6">
							Royal Purple
						</span>
					</div>
				</SelectItem>
				<SelectItem
					noCheck
					value={'warning'}>
					<div className="flex items-center gap-4">
						<p
							className="font-semibold w-8 h-6 rounded-lg"
							style={{ background: iconColors.warning }}
						/>
						<span className="font-semibold text-surface-11 dark:text-surface-6">
							Sun Yellow
						</span>
					</div>
				</SelectItem>
				<SelectItem
					noCheck
					value={'pink'}>
					<div className="flex items-center gap-4">
						<p
							className="font-semibold w-8 h-6 rounded-lg"
							style={{ background: iconColors.pink }}
						/>
						<span className="font-semibold text-surface-11 dark:text-surface-6">
							Hot Pink
						</span>
					</div>
				</SelectItem>
				<SelectItem
					noCheck
					value={'teal'}>
					<div className="flex items-center gap-4">
						<p
							className="font-semibold w-8 h-6 rounded-lg"
							style={{ background: iconColors.teal }}
						/>
						<span className="font-semibold text-surface-11 dark:text-surface-6">
							Friendly Blue
						</span>
					</div>
				</SelectItem>
				<SelectItem
					noCheck
					value={'success'}>
					<div className="flex items-center gap-4">
						<p
							className="font-semibold w-8 h-6 rounded-lg"
							style={{ background: iconColors.success }}
						/>
						<span className="font-semibold text-surface-11 dark:text-surface-6">
							Bold Green
						</span>
					</div>
				</SelectItem>

				<SelectItem
					noCheck
					className="!flex gap-2"
					value={'danger'}>
					<div className="flex items-center gap-4">
						<p
							className="font-semibold w-8 h-6 rounded-lg"
							style={{ background: iconColors.danger }}
						/>
						<span className="font-semibold text-surface-11 dark:text-surface-6">
							Active Red
						</span>
					</div>
				</SelectItem>
				<SelectItem
					noCheck
					value={'labelPrimary'}>
					<div className="flex items-center gap-4">
						<p
							className="font-semibold w-8 h-6 rounded-lg"
							style={{ background: iconColors.labelPrimary }}
						/>
						<span className="font-semibold text-surface-11 dark:text-surface-6">
							Space Grey
						</span>
					</div>
				</SelectItem>
			</SelectContent>
		</Select>
	);
};

export default Color;
