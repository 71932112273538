export const iconColors = {
	primary: '#007AFF',
	success: '#34C759',
	accent: '#5856D6',
	warning: '#FF9500',
	pink: '#FF2D55',
	purple: '#AF52DE',
	danger: '#FF3B30',
	teal: '#64D2FF',
	yellow: '#FFCC00',
	labelTertiary: '#6e6e6e',
	labelPrimary: '#3C3C43',
	gray: '#8E8E93',
	white: '#FFFFFF',
	black: '#000000',
};

export const iconColorsWithDark = {
	primary: {
		light: '#007AFF',
		dark: '#0047B3',
	},
	success: {
		light: '#34C759',
		dark: '#228B22',
	},
	accent: {
		light: '#5856D6',
		dark: '#3A36B1',
	},
	warning: {
		light: '#FF9500',
		dark: '#FF6F00',
	},
	pink: {
		light: '#FF2D55',
		dark: '#FF0032',
	},
	purple: {
		light: '#AF52DE',
		dark: '#7828B4',
	},
	danger: {
		light: '#FF3B30',
		dark: '#FF0000',
	},
	teal: {
		light: '#64D2FF',
		dark: '#00B2D9',
	},
	yellow: {
		light: '#FFCC00',
		dark: '#FFD500',
	},
	labelTertiary: {
		light: '#6e6e6e',
		dark: '#a8a8a8',
	},
	labelPrimary: {
		light: '#3C3C43',
		dark: '#EBEBF0',
	},
	gray: {
		light: '#8E8E93',
		dark: '#636366',
	},
	white: {
		light: '#FFFFFF',
		dark: '#1C1C1E',
	},
	black: {
		light: '#000000',
		dark: '#FFFFFF',
	},
};

export const HOUR_HEIGHT = 80; // 80px per hour
export const CALENDAR_TRACK_HEIGHT = 2080;

export const ZINDEX = {
	DROPDOWN: 1000,
	TOOLTIP: 1000,
	TOAST: 2000,
	MODAL: 5000,
};
