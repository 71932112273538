import { ReactNode, useEffect, useState } from 'react';
import ApplicationContainer from './ApplicationContainer';
import TodoPageEmptyState from '../features/todo/components/todo-utils/EmptyState';
import { AnimatePresence } from 'framer-motion';
import { useTodoPageLayoutNavigation } from './hooks';

import { useLayoutsContext } from '../context/HeaderContext';
import DraggableTodoListItem from '../features/todo/components/todo-utils/DraggableTodoListItem';
import { _SnackTodo } from '../lib/core/types';
import { deleteTodo } from '../lib/todos';
import TodoListItem from '../features/todo/components/TodoListItem';

type TodoListPage = {
	todos: _SnackTodo[];
	pinned?: _SnackTodo[];
	title?: string;
	documentTitle: string;
	showUpComing?: boolean;
	isEmpty?: boolean;
	excludeToday?: boolean;
};

export default function TodoListPage(props: TodoListPage) {
	const [selectedTodo, setSelectedTodo] = useState<string>();
	const { setHeaderProps } = useLayoutsContext();

	useTodoPageLayoutNavigation(props.todos, selectedTodo, setSelectedTodo);

	const onNavigate = (id: string) => {
		if (selectedTodo === id) setSelectedTodo(null);
		setSelectedTodo(id);
	};

	const onSelectTodo = (id: string) => {
		setSelectedTodo(id);
	};

	const onArchiveTodo = () => {
		deleteTodo(selectedTodo);
		setSelectedTodo(null);
	};

	useEffect(() => {
		document.title = `${props.documentTitle} - Tudu`;
	}, []);

	useEffect(() => {
		setHeaderProps({
			title: props.documentTitle,
			options: {
				create: true,
			},
		});
	}, [selectedTodo]);

	useEffect(() => {
		if (selectedTodo) {
			const todos = Object.keys(props.todos)
				.map((key) => props.todos.map((item) => item))
				.flat(5);
			if (!todos.find((todo) => todo.id === selectedTodo)) {
				setSelectedTodo(null);
			}
		}
	}, [selectedTodo, props.todos]);

	return (
		<ApplicationContainer hasCalendar>
			<div className="flex flex-col">
				<AnimatePresence
					mode="popLayout"
					initial={false}>
					{props.todos.length > 0 &&
						props.todos
							.sort((a, b) => {
								// sort with deadline or createdAt, both are dates, show the !complete ones first
								if (a.is_complete === b.is_complete) {
									if (a._when && b._when) {
										return (
											-new Date(a._when).getTime() - new Date(b._when).getTime()
										);
									} else {
										return (
											-new Date(a.inserted_at).getTime() -
											new Date(b.inserted_at).getTime()
										);
									}
								} else {
									return a.is_complete ? 1 : -1;
								}
							})
							.map((todo, idx) => (
								<TodoListItem
									key={`todo-item-${todo.id}`}
									idx={idx}
									onExpandTodo={() => onNavigate(todo.id)}
									onCollapseTodo={() => setSelectedTodo(null)}
									isSelected={todo.id === selectedTodo}
									selectedTodo={selectedTodo}
									onSelectTodo={(isFocused: boolean) =>
										onSelectTodo(isFocused && todo.id)
									}
									{...todo}
								/>
							))}
				</AnimatePresence>
			</div>

			{props.isEmpty && (
				<div className="p-4">
					<TodoPageEmptyState />
				</div>
			)}
		</ApplicationContainer>
	);
}
