import React, { ReactNode, createContext, useContext, useState } from 'react';
import { PageHeader } from '../components/navigation/header';

const LayoutsContext = createContext<{
	setHeaderProps: (props: PageHeader) => void;
	headerProps: PageHeader;
}>({
	setHeaderProps: (props) => null,
	headerProps: null,
});

export const useLayoutsContext = () => {
	const { setHeaderProps, headerProps } = useContext(LayoutsContext);

	return {
		setHeaderProps,
		headerProps,
	};
};

export default function LayoutsContextProvider(props: { children: ReactNode }) {
	const [headerProps, setHeaderProps] = useState<PageHeader>({});

	const onUpdateHeaderProps = (props: PageHeader) => {
		setHeaderProps(props);
	};

	return (
		<LayoutsContext.Provider
			value={{ setHeaderProps: onUpdateHeaderProps, headerProps }}>
			{props.children}
		</LayoutsContext.Provider>
	);
}
