import TodoBackend from '.';

interface SubTodo {
	id: number;
	title: string;
	_when?: Date;
	is_complete?: boolean;
	inserted_at?: Date;
}

export interface ITodo extends SubTodo {
	description?: string;
	subtasks?: SubTodo[];
}

abstract class ATodo implements ITodo, SubTodo {
	id: number;
	title: string;
	description?: string;
	_when?: Date;
	is_complete?: boolean;
	subtasks?: SubTodo[];
	inserted_at?: Date;

	update: (todo: ITodo) => Promise<void>;
	delete: (id: number) => Promise<void>;
	addSubtask: (todo: ITodo) => Promise<void>;
	deleteSubtask: (id: number) => Promise<void>;
}

export default class Todo implements ATodo {
	id: number;
	title: string;
	description?: string;
	_when?: Date;
	is_complete?: boolean;
	subtasks?: SubTodo[];
	inserted_at?: Date;

	constructor(
		body: ITodo,
		public backend: TodoBackend,
	) {
		this.id = body.id;
		this.title = body.title;
		this.description = body.description;
		this._when = body._when;
		this.is_complete = body.is_complete;
	}

	update = async (todo: ITodo) => {};

	delete = async (id: number) => {
		this.backend.delete(id.toString());
	};

	addSubtask: () => Promise<void>;

	deleteSubtask = async (id: number) => {
		this.backend.delete(id.toString());
	};
}
