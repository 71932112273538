('use client');
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { assets } from '../../assets';
import Button from '../../components/ui/button';
import { motion } from 'framer-motion';
import supabase from '../../lib/core/supabase';
import { useUser } from '../../context/AuthContext';
import { useEffect } from 'react';

export default function GetStarted() {
	const navigate = useNavigate();
	const user = useUser();

	const onQuickStart = () => {
		supabase.auth
			.signInWithOAuth({
				provider: 'google',
			})
			.catch(console.error);
	};

	useEffect(() => {
		if (user?.isAuthenticated && !user?.isLoading) navigate('/home');
	}, [user]);

	return (
		<main
			className={
				'h-screen w-screen flex items-center justify-center top-0 left-0 z-[9999] fixed bg-white dark:bg-surface-12 p-8'
			}>
			<div className="w-full max-w-screen-md">
				<div className="flex flex-col items-center mb-16">
					<motion.img
						src={assets.images.AppLogo}
						alt={'Logo'}
						height={96}
						width={96}
						className="mb-16"
						animate={{
							scale: [1, 1.2, 1],
						}}
						transition={{
							repeat: Infinity,
							duration: 3,
						}}
					/>
					{!user?.isLoading && !user?.isAuthenticated && (
						<>
							<div className="max-w-2xl mb-12 space-y-6 mx-auto">
								<p className="text-3xl lg:text-5xl font-bold text-center tracking-tight text-balance text-slate-950">
									Personal, delightful and simple todos
								</p>
							</div>
							<div className="flex justify-center gap-4 mt-4 max-w-xl w-full">
								<Button
									onClick={onQuickStart}
									className="px-8 py-3 w-full text-white justify-center flex bg-sky-500 hover:bg-sky-600 hover:text-white rounded-xl font-bold">
									Sign in to get started
									<ArrowRightIcon className="w-5 h-5" />
								</Button>
							</div>
						</>
					)}
				</div>
				<div className="fixed left-0 flex justify-between w-full gap-2 px-4 lg:px-16 mt-auto bottom-2">
					<div>
						<p>
							<span className="text-zinc-500">
								Hurudza © {new Date().getFullYear()}
							</span>
						</p>
					</div>
					<p className="uppercase text-zinc-500 text-number text-sm">
						Version {process.env.REACT_APP_VERSION}
					</p>
				</div>
			</div>
		</main>
	);
}
