import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import Textarea from '../../../../components/ui/input/textarea';
import {
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import Kbd, { Keys } from '../../../../components/ui/typography/Kbd';
import { stopAllPropagation } from '../../../../lib/functions/utils';
import useDisclosure from '../../../../lib/hooks/useDisclosure';
import { useKeyboardShortcuts } from '../../../../context/KbdNavigationContext';
import useClickOutside from '../../../../lib/hooks/useClickOutside';
import { _SnackTodo } from '../../../../lib/core/types';
import Todo from '../../classes/todo';
import useTodos from '../../../../lib/hooks/useTodos';
import useDebounce from '../../../../lib/hooks/useDebounce';

type DetailedTodoListItemProps = _SnackTodo & {
	title: string;
	is_complete?: boolean;
	_when?: Date;
	subtodos: {
		complete: boolean;
	}[];
	description?: string;
	id: string;
	quickActions?: ReactNode;
	isDragging?: boolean;
	todo: Todo;

	onCheck: (e) => void;
	onCollapse: () => void;
};

export default function DetailedTodoListItem(props: DetailedTodoListItemProps) {
	const titleRef = useRef();
	const containerRef = useRef<HTMLDivElement>();
	const descriptionRef = useRef<HTMLTextAreaElement>();
	const { isOpen: hasDescriptionField, onOpen: addDescriptionField } =
		useDisclosure();
	const { updateTodo } = useTodos();
	const [title, setTitle] = useState(props.title);
	const [description, setDescription] = useState(props.description);

	useDebounce(description, 1000);
	useDebounce(title, 1000);

	const onDescriptionChange = (description) => {
		updateTodo({
			...props,
			description,
		});
	};

	const onTitleChange = (title) => {
		updateTodo({
			...props,
			title,
		});
	};

	useEffect(() => {
		onDescriptionChange(description);
	}, [description]);

	useEffect(() => {
		onTitleChange(title);
	}, [title]);

	useClickOutside(containerRef, props.onCollapse);

	useEffect(() => {
		if (props.description) {
			addDescriptionField();
		}
	}, [props.description]);

	/** Keyboard event listeners */
	const enterKeyListener = useCallback(
		(evt: KeyboardEvent) => {
			// If user presses enter in title field, lets submit the form
			if (evt.key === 'Enter' && !evt.shiftKey) {
				descriptionRef.current?.focus();
				evt.stopImmediatePropagation();
				evt.stopPropagation();
				evt.preventDefault();
				return;
			}
		},
		[titleRef.current],
	);

	const titleShiftEnterKeyListener = useCallback((evt: KeyboardEvent) => {
		// If user presses shift + enter in title field, lets add a description field
		if (evt.metaKey) {
			stopAllPropagation(evt);
			addDescriptionField();
			return;
		}
	}, []);

	const listeners = useMemo(
		() => [
			{
				key: 'd',
				callback: titleShiftEnterKeyListener,
				label: 'Add notes',
				auxillary: [Keys.Meta],
			},
		],
		[titleShiftEnterKeyListener],
	);

	useKeyboardShortcuts(listeners);

	return (
		<motion.div
			ref={containerRef}
			layout
			layoutId={`todo-${props.id}`}
			initial={{
				opacity: 0,
				top: 0,
			}}
			animate={{
				opacity: 1,
			}}
			exit={{
				opacity: 0,
			}}
			transition={{
				type: 'spring',
				mass: 2,
				stiffness: 2000,
				damping: 100,
				velocity: 50,
			}}
			style={{ originY: 0 }}
			className="flex items-start w-full gap-2 flex-1 h-full rounded-xl py-3 ">
			<motion.input
				whileHover={{ scale: 1.1 }}
				className="flex-shrink-0 rounded-xl relative z-1 !mt-0.5"
				type="checkbox"
				onChange={props.onCheck}
				checked={props.is_complete}
			/>

			<div className="flex-1 space-y-2 pr-2">
				<div className="flex items-start space-x-2 justify-between">
					<Textarea
						className={clsx(
							'pr-4 w-full max-w-screen-lg outline-none font-semibold text-zinc-900 paragaph dark:text-white',
						)}
						value={title}
						name={'title'}
						onChange={(e) => setTitle(e.target.value)}
						listeners={{
							keydown: [enterKeyListener],
						}}
						setRef={titleRef}
						autoFocus
					/>
				</div>

				{!description && !hasDescriptionField && (
					<AnimatePresence>
						<motion.div
							initial={{ y: -10, opacity: 0 }}
							exit={{ y: 0 - 10, opacity: 0 }}
							animate={{ y: 0, opacity: 1 }}
							className="flex items-center">
							<p className="text-surface-8">
								Press <Kbd keys={[Keys.Meta, 'd']} /> to write additional notes
							</p>
						</motion.div>
					</AnimatePresence>
				)}

				{hasDescriptionField && (
					<motion.div
						initial={{ y: -10, opacity: 0 }}
						exit={{ y: 0 - 10, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ duration: 0.3 }}
						className="w-full ">
						<Textarea
							onChange={(e) => setDescription(e.target.value)}
							value={description}
							name={'description'}
							className={
								'bg-transparent text-surface-12 dark:text-white max-w-screen-lg w-full outline-none mb-0'
							}
							placeholder="Additional notes"
							setRef={descriptionRef}
							autoFocus
						/>
					</motion.div>
				)}
			</div>
		</motion.div>
	);
}
