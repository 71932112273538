import { ForwardedRef, ReactNode, forwardRef } from 'react';
import Dropdown from '../../../ui/dropdown-menu';
import tabs from '../tabs';
import SFSymbol from '../../../../assets/icons/SFSymbol';
import useColor from '../../../../lib/hooks/useColor';
import { iconColors } from '../../../../constants/style';
import { useNavigate } from 'react-router-dom';

const DockItemsDropdown = (props: {
	folders: Array<any>;
	children: ReactNode;
}) => {
	const navigate = useNavigate();
	const color = useColor(iconColors.warning);
	const folders = props.folders.map((folder) => ({
		label: folder.name,
		value: folder.id,
		isFolder: true,
		icon: (
			<SFSymbol
				name="circle.dotted"
				color={color}
			/>
		),
	}));

	const onNavigate = (href: string) => {
		navigate(`/home?list=${href}`);
		console.log({ href });
	};

	console.log({ tabs });

	return (
		<Dropdown>
			<Dropdown.Trigger className="flex flex-shrink-0 flex-nowrap p-0">
				{props.children}
			</Dropdown.Trigger>
			<Dropdown.Content>
				{tabs.map((tab) => (
					<Dropdown.Item
						onClick={() => onNavigate(tab.value)}
						key={tab.value}>
						{tab.icon}
						<span>{tab.label}</span>
					</Dropdown.Item>
				))}
				{folders.length > 0 && (
					<>
						<Dropdown.Separator />
						{folders.map((tab) => (
							<Dropdown.Item key={tab.value}>
								{tab.icon}
								<span>{tab.label}</span>
							</Dropdown.Item>
						))}
					</>
				)}
			</Dropdown.Content>
		</Dropdown>
	);
};

export default DockItemsDropdown;
