import { createContext, useEffect, useState } from 'react';

export const DateContext = createContext<Date | undefined>(new Date());

const DateContextProvider = (props) => {
	const [date, setDate] = useState(new Date());

	useEffect(() => {
		const listener = () => {
			setDate(new Date());
		};

		const interval = setInterval(listener, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<DateContext.Provider value={date}>{props.children}</DateContext.Provider>
	);
};

export default DateContextProvider;
