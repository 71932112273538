import { createContext, ReactNode, useState } from 'react';
import useToggle from '../lib/hooks/useToggle';
import CreateTodo from '../features/todo/components/todo-add';
import Modal from '../components/ui/modal';

export const CommandContext = createContext({
	openCreateTodo: (listId: string) => null,
	isCreateTodoOpen: false,
});

const CommandContextProvider = (props: { children: ReactNode }) => {
	const [activeProject, setActiveProject] = useState<string | null>();
	const [isCreateTodoOpen, toggleCreateTodo, setIsCreateTodoOpen] =
		useToggle(false);

	const onOpenCreateTodo = (projectId: string) => {
		setActiveProject(projectId);
		toggleCreateTodo();
	};

	const onCloseCreateTodo = () => {
		setActiveProject(null);
		toggleCreateTodo();
	};

	return (
		<CommandContext.Provider
			value={{
				openCreateTodo: onOpenCreateTodo,
				isCreateTodoOpen,
			}}>
			<Modal
				isOpen={isCreateTodoOpen}
				onClose={onCloseCreateTodo}
				noPadding
				className={'max-w-xl '}>
				<CreateTodo
					overrideOpenState={isCreateTodoOpen}
					overrideToggle={() => setIsCreateTodoOpen(false)}
					defaultList={activeProject}
				/>
			</Modal>

			{props.children}
		</CommandContext.Provider>
	);
};

export default CommandContextProvider;
