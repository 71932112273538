import { useContext } from 'react';
import { TodoContext } from '../../context/TodosContext';

const useTodos = () => {
	const { todos, addTodo, deleteTodo, setTodos, updateTodo } =
		useContext(TodoContext);

	return { todos: todos ?? [], addTodo, deleteTodo, setTodos, updateTodo };
};

export default useTodos;
