import React, { useEffect, useState } from 'react';

import { useAnimate, motion, AnimatePresence, useInView } from 'framer-motion';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogOverlay,
	DialogTitle,
} from '../dialog';
import { cn } from '../../../lib/functions/utils';
import { createPortal } from 'react-dom';
import { DialogPortal } from '@radix-ui/react-dialog';

export type ModalProps = {
	children?: React.ReactNode;
	isOpen: boolean;
	onClose: () => void;
	title?: string;
	description?: string;
	actionCenter?: React.ReactNode;
	noPadding?: boolean;
	className?: string;
	size?: 'default' | 'lg' | 'xl' | '2xl';
};

const keyframes = [0, 0.1, 0.25, 0.5, 0.75, 1];

export default function Modal({
	children,
	isOpen,
	onClose,
	title,
	description,
	actionCenter,
	noPadding,
	className,
	size,
}: ModalProps) {
	const [scope, animate] = useAnimate();
	const isInView = useInView(scope);

	useEffect(() => {
		const enterAnimation = async () => {
			await animate(scope.current, { scale: keyframes, opacity: 1 });
		};

		if (isInView) {
			enterAnimation();
		}
	}, [isInView]);

	return (
		<Dialog
			open={isOpen}
			onOpenChange={onClose}>
			<AnimatePresence>
				{isOpen && (
					<DialogPortal>
						<DialogOverlay />
						<DialogContent
							ref={scope}
							style={{
								transformOrigin: 'bottom center',
							}}
							autoFocus={false}
							className={cn(
								'flex justify-center overflow-y-auto backdrop-blur-lg shadow-card h-fit',
								'outline-none fixed inset-x-0 mx-auto bottom-[2rem] z-40 max-w-xl w-full rounded-2xl',
								!noPadding && 'p-4 pb-2',
								className,
								size === 'lg' && 'w-[720px] max-w-none',
								size === 'xl' && 'w-[960px] max-w-none',
							)}>
							{(title || description) && (
								<DialogHeader className="w-full mb-4">
									{title && (
										<DialogTitle className={'text-xl font-semibold'}>
											{title}
										</DialogTitle>
									)}
									{description && (
										<DialogDescription className={'text-zinc-500'}>
											{description}
										</DialogDescription>
									)}
								</DialogHeader>
							)}

							{children}

							{actionCenter && (
								<div className="p-2 bg-surface-1 border-t border-zinc-900/10 rounded-b-lg">
									{actionCenter}
								</div>
							)}
						</DialogContent>
					</DialogPortal>
				)}
			</AnimatePresence>
		</Dialog>
	);
}
