import { useEffect, useMemo } from 'react';
import TodosPage from '.';
import {
	KeyboardEventListener,
	useKeyboardShortcuts,
} from '../../../../context/KbdNavigationContext';
import { Keys } from '../../../../components/ui/typography/Kbd';
import useToggle from '../../../../lib/hooks/useToggle';
import { useSearchParams } from 'react-router-dom';
import tabs from '../../../../components/navigation/header/tabs';
import useTodos from '../../../../lib/hooks/useTodos';
import supabase from '../../../../lib/core/supabase';
import { useUser } from '../../../../context/AuthContext';

const TodoRoute = () => {
	const [showCompleteTodos, toggleShowCompleteTodos] = useToggle(false);
	const { todos } = useTodos();
	const { user } = useUser();
	const searchParams = useSearchParams();
	const list = useMemo(
		() => (searchParams[0].getAll('list')[0] ?? 'all') as string,
		[searchParams],
	);

	const listLabel = useMemo(() => {
		const tab = tabs.find((tab) => tab.value === list);
		// if its a tab, return the label. otherwise just return the list name
		if (tab) return tab.label;
		return list;
	}, [list]);

	const filteredTodos = useMemo(
		() =>
			todos.filter((todo) => (!showCompleteTodos ? todo.is_complete : true)),
		[showCompleteTodos, todos],
	);

	const toggleShowCompleteTodosCb = (e: KeyboardEvent) => {
		if (e.metaKey) {
			toggleShowCompleteTodos();
		}
	};

	const listeners: Array<KeyboardEventListener> = useMemo(
		() => [
			{
				key: ',',
				auxillary: [Keys.Meta],
				callback: toggleShowCompleteTodosCb,
			},
		],
		[],
	);

	useKeyboardShortcuts(listeners);

	return (
		<TodosPage
			query={list}
			title={listLabel}
			todos={todos}
		/>
	);
};

export default TodoRoute;
