import SFSymbol from '../../../../assets/icons/SFSymbol';

export default function TodoPageEmptyState(props: { title?: string }) {
	return (
		<div className="w-full h-full flex items-center">
			<div>
				<p className={'mx-auto'}>There are no todos in this list</p>
			</div>
		</div>
	);
}
