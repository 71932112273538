import { DndContext } from '@dnd-kit/core';
import '../styles/global.css';
import { Provider } from 'react-redux';
import { ReactNode, useEffect, useMemo } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toaster } from 'sonner';
import CommandContextProvider from '../context/CommandContext';
import { ToastProvider } from '../context/ToastContext';
import KbdNavigationContextProvider from '../context/KbdNavigationContext';
import DateContextProvider from '../context/DateContext';
import { TooltipProvider } from '../components/ui/tooltip';
import AuthContextProvider from '../context/AuthContext';
import TodoContextProvider from '../context/TodosContext';

export default function SnackApplicationProvider(props: {
	children: ReactNode;
}) {
	return (
		<div
			className="flex flex-col w-screen h-screen mx-auto overflow-hidden text-base subpixel-antialiased font-normal text-surface-11 dark:text-surface-a11 backdrop-blur"
			id="app-container">
			<title>Tudu ⏲</title>
			<AuthContextProvider>
				<DateContextProvider>
					<TodoContextProvider>
						<TooltipProvider>
							<KbdNavigationContextProvider>
								<ToastProvider>
									<CommandContextProvider>
										<Toaster />

										<DndContext>{props.children}</DndContext>
									</CommandContextProvider>
								</ToastProvider>
							</KbdNavigationContextProvider>
						</TooltipProvider>
					</TodoContextProvider>
				</DateContextProvider>
			</AuthContextProvider>
		</div>
	);
}
