import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { cn } from '../../../../lib/functions/utils';
import { ForwardedRef, forwardRef } from 'react';
import useDockItemTodoCounter from './hooks/useDockItemTodoCounter';

const DockItem = forwardRef(
	(
		props: {
			index: number;
			tab: {
				href: string;
				label: string;
				value: string;
				isFolder?: boolean;
			};
		},
		ref: ForwardedRef<HTMLButtonElement>,
	) => {
		const navigate = useNavigate();
		const query = new URLSearchParams(useLocation().search);
		const todoCount = useDockItemTodoCounter(props.tab);

		const onNavigate = (href: string) => {
			navigate(`/home?list=${props.tab.value}`);
		};

		const isActive = query.get('list')
			? query.get('list') === props.tab.value
			: props.tab.value === 'all';

		return (
			<motion.button
				ref={ref}
				initial={{ x: -8 * (props.index + 1), opacity: 0 }}
				animate={{ x: 0, opacity: 1 }}
				exit={{ x: -8 * (props.index + 1), opacity: 0 }}
				transition={{ duration: 0.1, type: 'spring' }}
				onClick={() => onNavigate(props.tab.href)}
				className={cn(
					'py-1 no-drag rounded-xl flex z-0 flex-shrink-0 text-zinc-500 hover:text-surface-12 dark:hover:text-white items-baseline gap-2 w-fit hover:bg-sky-400/20 transition-colors px-3',
					isActive &&
						'bg-sky-500 !text-white hover:bg-sky-500 dark:text-white font-semibold',
				)}
				key={props.tab.href}>
				{props.tab.label}
				<span className="text-sm text-number tracking-tight font-normal">
					{todoCount}
				</span>
			</motion.button>
		);
	},
);

export default DockItem;
