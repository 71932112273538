import { useEffect, useMemo, useState } from 'react';
import { iconColorsWithDark } from '../../constants/style';

const useColor = (base: string) => {
	const [isInDarkMode, setIsDarkMode] = useState(false);

	useEffect(() => {
		const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');

		const handleChange = () => {
			setIsDarkMode(darkModeQuery.matches);
		};

		darkModeQuery.addEventListener('change', handleChange);

		// Initial check
		handleChange();

		return () => darkModeQuery.removeEventListener('change', handleChange);
	}, []);
	// find the key which has the color
	const keyOfColor = Object.keys(iconColorsWithDark).find(
		(key) => iconColorsWithDark[key].light === base,
	);
	const colorWithDark = useMemo(() => {
		if (!keyOfColor) return base;
		if (isInDarkMode) {
			return iconColorsWithDark[keyOfColor].dark;
		}
		return iconColorsWithDark[keyOfColor].light;
	}, [keyOfColor, isInDarkMode]);

	return colorWithDark;
};

export default useColor;
