import React from 'react';

import * as DialogPrimitive from '@radix-ui/react-dialog';
import clsx from 'clsx';
import { DialogClose } from '@radix-ui/react-dialog';
import SFSymbol from '../../../assets/icons/SFSymbol';
import { cn } from '../../../lib/functions/utils';

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = ({
	className,
	...props
}: DialogPrimitive.DialogPortalProps) => (
	<DialogPrimitive.Portal
		className={clsx(className)}
		{...props}
	/>
);
DialogPortal.displayName = DialogPrimitive.Portal.displayName;

const DialogOverlay = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Overlay>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Overlay
		ref={ref}
		className={clsx(
			'fixed inset-0 z-40 bg-zinc-950/30 dark:bg-zinc-950/50 mix-blend-darken',
			className,
		)}
		{...props}
	/>
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
	<DialogPrimitive.Content
		ref={ref}
		className={cn(
			'gap-4 z-50 grid bottom-8 rounded-2xl w-full',
			'backdrop-blur bg-white/90 dark:bg-zinc-800/90',
			className,
		)}
		{...props}>
		{children}
	</DialogPrimitive.Content>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) => (
	<div
		className={clsx(
			'flex flex-col space-y-1.5 text-center items-center justify-center sm:text-left py-2 px-4 border-b relative h-14',
			className,
		)}
		{...props}>
		{props.children}
		<DialogClose
			style={{ top: '50%', transform: 'translateY(-50%)' }}
			className={'absolute right-4 !mt-0'}>
			<SFSymbol
				name={'xmark.circle'}
				className={'w-7 h-7'}
			/>
		</DialogClose>
	</div>
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) => (
	<div
		className={clsx(
			'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2',
			className,
		)}
		{...props}
	/>
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Title>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Title
		ref={ref}
		className={clsx(
			'font-medium !text-lg leading-none tracking-tight',
			className,
		)}
		{...props}
	/>
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Description>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Description
		ref={ref}
		className={clsx('text-surface-10', className)}
		{...props}
	/>
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
	Dialog,
	DialogTrigger,
	DialogContent,
	DialogHeader,
	DialogFooter,
	DialogTitle,
	DialogDescription,
	DialogOverlay,
};
