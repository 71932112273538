import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import supabase from '../lib/core/supabase';
import { SupabaseClient } from '@supabase/supabase-js';

export type User = {
	id: string;
	email: string;
	displayName: string;
	avatar?: string;
};

type AuthContextType = {
	isAuthenticated: boolean;
	isLoading: boolean;
	user: User | undefined;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [user, setUser] = useState<User>();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		supabase.auth
			.getSession()
			.then((session) => {
				if (session && session.data) {
					const { data } = session;
					if (data.session) {
						setUser({
							id: data.session.user.id,
							email: data.session.user.user_metadata.email,
							displayName: data.session.user.user_metadata.full_name,
						});
						setIsAuthenticated(true);
					}
				} else {
					setIsAuthenticated(false);
				}
			})
			.finally(() => setIsLoading(false));
	}, []);

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				user,
				isLoading,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

interface ISnackUser {
	client: SupabaseClient;
	user: User;

	resetWorkspace: () => Promise<void>;
	logout: () => Promise<void>;
}

class SnackUser implements ISnackUser {
	client: SupabaseClient<any, 'public', any>;
	user: User;

	constructor(client: SupabaseClient, user: User) {
		this.client = client;
		this.user = user;
	}

	resetWorkspace = async () => {};

	logout = async () => {
		await this.client.auth.signOut();
		window.location.reload();
	};
}

const useUser = () => {
	const authContext = useContext(AuthContext);

	const user = useMemo(() => {
		if (authContext.user && authContext.isAuthenticated) {
			return new SnackUser(supabase, authContext.user);
		}
	}, [authContext.isAuthenticated, authContext.user]);

	if (!authContext) {
		throw new Error('useUser must be used within an AuthProvider');
	}

	return { ...authContext, lib: user };
};

export { AuthContext, useUser };

export default AuthContextProvider;
