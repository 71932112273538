import useToggle from '../../../../lib/hooks/useToggle';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Textarea from '../../../../components/ui/input/textarea';
import { generateUUID } from '../../../../lib/functions';
import { useFormik } from 'formik';
import useDisclosure from '../../../../lib/hooks/useDisclosure';
import SFSymbol from '../../../../assets/icons/SFSymbol';
import { iconColors } from '../../../../constants/style';
import Color from '../todo-color';
import supabase from '../../../../lib/core/supabase';
import { _SnackTodo } from '../../../../lib/core/types';
import { useUser } from '../../../../context/AuthContext';
import useTodos from '../../../../lib/hooks/useTodos';

const CreateTodo = (props: {
	defaultList?: string;
	overrideOpenState?: boolean;
	overrideToggle?: () => void;
}) => {
	const ref = useRef<HTMLButtonElement>(null);
	const [, toggle] = useToggle(props.overrideOpenState);

	const toggleWithCb = () => {
		toggle();
		if (props.overrideToggle) props.overrideToggle();
	};

	useEffect(() => {
		if (ref.current) {
			const evtListener = (evt: KeyboardEvent) => {
				// Listen for Ctrl + Enter
				if (evt.key === 'Enter' && evt.ctrlKey) {
					evt.preventDefault();
					evt.stopPropagation();
					evt.stopImmediatePropagation();
					toggleWithCb();
				}
			};

			ref.current.addEventListener('keydown', evtListener);

			return () => {
				ref.current?.removeEventListener('keydown', evtListener);
			};
		}
	}, [ref.current]);

	return (
		<CreateTodoForm
			toggle={toggleWithCb}
			defaultList={props.defaultList}
		/>
	);
};

const CreateTodoForm = (props: {
	toggle: () => void;
	defaultList?: string;
}) => {
	const {
		isOpen: hasDescriptionField,
		onOpen: addDescriptionField,
		onClose: removeDescriptionField,
	} = useDisclosure();
	const formRef = useRef<HTMLFormElement>(null);
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const descriptionRef = useRef<HTMLTextAreaElement>(null);
	const id = useMemo(() => generateUUID(), []);
	const now = new Date();
	const user = useUser();
	const { addTodo } = useTodos();

	const form = useFormik({
		initialValues: {
			title: '',
			_when: undefined,
			description: '',
			color: iconColors.primary,
			list: props.defaultList || 'default',
		},
		onSubmit: (values) => {
			onSubmit(values);
		},
		validateOnChange: true,
		// TODO: Add validation schema here
	});

	const onSubmit = async (data: typeof form.values) => {
		if (!data.title) return;

		let todo: _SnackTodo = {
			id,
			title: data.title,
			description: data.description,
			is_complete: false,
			inserted_at: new Date(),
		};

		addTodo({
			inserted_at: todo.inserted_at,
			title: todo.title,
			description: todo.description,
			_when: data._when,
			is_complete: todo.is_complete,
			id: todo.id,
		});

		// dispatch(
		// 	addTodoToList({
		// 		listId: data.list,
		// 		todoId: todo.id,
		// 		columnId: SnackTodoStatus.Todo,
		// 	}),
		// );

		form.resetForm();
		props.toggle();
	};

	const stopAllPropagation = (evt: KeyboardEvent) => {
		evt.preventDefault();
		evt.stopPropagation();
		evt.stopImmediatePropagation();
	};

	const enterKeyListener = useCallback(
		(evt: KeyboardEvent) => {
			// If user presses enter in title field, lets submit the form
			if (evt.key === 'Enter' && !evt.shiftKey) {
				stopAllPropagation(evt);
				form.submitForm().then(props.toggle);
			}
		},
		[textAreaRef.current, form, onSubmit],
	);

	const titleShiftEnterKeyListener = useCallback(
		(evt: KeyboardEvent) => {
			// If user presses shift + enter in title field, lets add a description field
			if (evt.key === 'Enter' && evt.shiftKey) {
				stopAllPropagation(evt);
				addDescriptionField();
			}
		},
		[textAreaRef.current, form],
	);

	const backspaceDescriptionListener = useCallback(
		(evt: KeyboardEvent) => {
			// If user presses backspace in description field and its already empty, lets remove the field
			if (evt.key === 'Backspace' && descriptionRef.current?.value === '') {
				stopAllPropagation(evt);
				// Focus back on title field
				removeDescriptionField();
				textAreaRef.current.focus();
			}
		},
		[descriptionRef.current, textAreaRef.current, form],
	);

	useEffect(() => {
		if (formRef.current) {
			const closeFormListener = (evt: KeyboardEvent) => {
				if (evt.key === 'Escape') props.toggle();
			};

			formRef.current.addEventListener('keydown', closeFormListener);

			return () => {
				formRef.current?.removeEventListener('keydown', closeFormListener);
			};
		}
	}, []);

	return (
		<motion.form
			className="flex flex-col w-full pb-2 divide-y divide-zinc-400/10 dark:divide-zinc-400/10"
			ref={formRef}
			onSubmit={form.handleSubmit}>
			<div className={'flex-1 w-full p-4 space-y-2'}>
				<div className="flex gap-4">
					<Textarea
						setRef={textAreaRef}
						rows={1}
						required
						placeholder={'New todo'}
						className={
							'outline-none flex-1 ring-0 text-surface-12 dark:text-white w-full font-semibold h-fit no-drag'
						}
						name={'title'}
						autoFocus
						listeners={{
							keydown: [enterKeyListener, titleShiftEnterKeyListener],
						}}
						onChange={form.handleChange}
						value={form.values.title}
					/>
				</div>
				{hasDescriptionField && (
					<Textarea
						setRef={descriptionRef}
						placeholder="Additional notes"
						name={'description'}
						className={
							'outline-none ring-0 flex-1 text-surface-12 dark:text-surface-6 w-full no-drag'
						}
						autoFocus
						onChange={form.handleChange}
						value={form.values.description}
						listeners={{
							keydown: [backspaceDescriptionListener],
						}}
					/>
				)}
			</div>

			<AnimatePresence>
				<BottomBar form={form} />
			</AnimatePresence>
		</motion.form>
	);
};

const BottomBar = (props: { form: any }) => {
	return (
		<div className="flex items-center justify-between p-3 pb-1">
			<div className={'flex space-x-2'}>
				<Color
					value={props.form.values.color}
					onChange={(val) => props.form.setFieldValue('color', val)}
				/>
			</div>
			<motion.button
				disabled={!props.form.isValid}
				type={'submit'}
				style={{ background: iconColors.primary }}
				className={
					'text-white font-semibold px-6 py-2 rounded-xl text-base shadow'
				}>
				<SFSymbol
					name={'paperplane.fill'}
					color={iconColors.white}
					className="w-5 h-5"
				/>
				Add todo
			</motion.button>
		</div>
	);
};
export default CreateTodo;
