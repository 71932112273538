import {
	ArrowLeftOnRectangleIcon,
	BackspaceIcon,
	ChevronUpDownIcon,
	QuestionMarkCircleIcon,
} from '@heroicons/react/20/solid';
import { useUser } from '../../../../context/AuthContext';
import Button from '../../../ui/button';
import Dropdown from '../../../ui/dropdown-menu';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

class SnackNavigation {
	constructor(public navigate: NavigateFunction) {}

	gotoHelp = () => {
		this.navigate('/help');
	};

	gotoTasks = () => {
		this.navigate('/home');
	};
}

export default function ProfileBtn() {
	const navigate = useNavigate();
	const appNavigator = useMemo(() => new SnackNavigation(navigate), [navigate]);
	const { user, lib } = useUser();

	const onLogout = async () => {
		await lib.logout();
	};
	const reset = async () => {
		await lib.resetWorkspace();
	};
	const help = () => {
		appNavigator.gotoHelp();
	};

	if (!user) return null;
	return (
		<Dropdown>
			<Dropdown.Trigger>
				<Button className="rounded-full gap-2 px-2 hover:bg-white hover:shadow">
					<span className="w-5 h-5 rounded-full bg-gradient-to-br from-amber-600 via-orange-400 to-orange-200" />
					{user.displayName}
					<ChevronUpDownIcon className="w-4 h-4" />
				</Button>
			</Dropdown.Trigger>
			<Dropdown.Content>
				<Dropdown.Item onClick={help}>
					<QuestionMarkCircleIcon className="w-5 h-5 text-zinc-500" />
					Help
				</Dropdown.Item>
				<Dropdown.Item onClick={onLogout}>
					<ArrowLeftOnRectangleIcon className="w-5 h-5 text-zinc-500" />
					Sign out
				</Dropdown.Item>
				<Dropdown.Separator />
				<Dropdown.Item className="text-red-500">
					<BackspaceIcon className="w-5 h-5 text-red-500" />
					Reset workspace
				</Dropdown.Item>
			</Dropdown.Content>
		</Dropdown>
	);
}
