'use client';
import { useContext, useEffect, useState } from 'react';

import { isSameDay } from 'date-fns';
import { DateContext } from '../../../../context/DateContext';
import { _SnackTodo } from '../../../../lib/core/types';
import TodoListPage from '../../../../layouts/TaskPageLayout';

export default function TodosPage(props: {
	todos: _SnackTodo[];
	query: string;
	title: string;
}) {
	const todosInList = useTodosForList(props.query, props.todos);
	return (
		<TodoListPage
			todos={todosInList}
			documentTitle={props.title}
			isEmpty={todosInList.length === 0}
			showUpComing
		/>
	);
}

const useTodosForList = (
	list: 'all' | 'today' | 'later' | 'complete' | string,
	todos: _SnackTodo[],
) => {
	const lists = [];
	const [todosForList, setTodosForList] = useState(todos);
	const today = useContext(DateContext);

	useEffect(() => {
		switch (list) {
			case 'all':
				setTodosForList(todos.filter((item) => !item.is_complete));
				break;
			case 'today':
				const todayTodos = todos.filter((todo) => {
					return (
						isSameDay(new Date(todo._when || todo.inserted_at), today) &&
						!todo.is_complete
					);
				});
				setTodosForList(todayTodos);
				break;
			case 'later':
				const laterTodos = todos.filter(
					(todo) =>
						todo._when &&
						new Date(todo._when).getTime() > new Date().getTime() &&
						!todo.is_complete,
				);
				setTodosForList(laterTodos);
				break;
			case 'complete':
				setTodosForList(todos.filter((item) => item.is_complete));
				break;
			default:
				const thisList = lists.find((item) => item.name === list);
				const todosInList = todos.filter((todo) =>
					thisList.todos.includes(todo.id),
				);
				setTodosForList(todosInList);
				break;
		}
	}, [todos, list]);

	return todosForList;
};
