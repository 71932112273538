export const generateUUID = (): string => {
	return crypto.randomUUID();
};

const timeZonesAndCities: {
	city: string;
	offset: number;
}[] = [
	{ city: 'Baker Island (US)', offset: -12 },
	{ city: 'Alofi (Niue)', offset: -11 },
	{ city: 'Honolulu (US)', offset: -10 },
	{ city: 'Anchorage (US)', offset: -9 },
	{ city: 'Los Angeles (US)', offset: -8 },
	{ city: 'Denver (US)', offset: -7 },
	{ city: 'Chicago (US)', offset: -6 },
	{ city: 'New York (US)', offset: -5 },
	{ city: 'Caracas (Venezuela)', offset: -4 },
	{ city: "St. John's (Canada)", offset: -3.5 },
	{ city: 'Buenos Aires (Argentina)', offset: -3 },
	{ city: 'Sao Paulo (Brazil)', offset: -2 },
	{ city: 'Azores (Portugal)', offset: -1 },
	{ city: 'Casablanca (Morocco)', offset: 0 },
	{ city: 'London (UK)', offset: 0 },
	{ city: 'Paris (France)', offset: 1 },
	{ city: 'Harare', offset: 2 },
	{ city: 'Tehran (Iran)', offset: 3.5 },
	{ city: 'Moscow (Russia)', offset: 3 },
	{ city: 'Dubai (UAE)', offset: 4 },
	{ city: 'Kabul (Afghanistan)', offset: 4.5 },
	{ city: 'Karachi (Pakistan)', offset: 5 },
	{ city: 'New Delhi (India)', offset: 5.5 },
	{ city: 'Kathmandu (Nepal)', offset: 5.75 },
	{ city: 'Dhaka (Bangladesh)', offset: 6 },
	{ city: 'Yangon (Myanmar)', offset: 6.5 },
	{ city: 'Bangkok (Thailand)', offset: 7 },
	{ city: 'Beijing (China)', offset: 8 },
	{ city: 'Tokyo (Japan)', offset: 9 },
	{ city: 'Adelaide (Australia)', offset: 9.5 },
	{ city: 'Sydney (Australia)', offset: 10 },
	{ city: 'Norfolk Island (Australia)', offset: 11 },
	{ city: 'Wellington (New Zealand)', offset: 12 },
	{ city: 'Chatham Islands (New Zealand)', offset: 12.75 },
	{ city: 'Apia (Samoa)', offset: 13 },
	{ city: 'Kiritimati (Kiribati)', offset: 14 },
];
export const getTimeZoneNameFromOffset = (offset: number): string => {
	const timeZone = timeZonesAndCities.find((tz) => tz.offset === offset);
	return timeZone ? timeZone.city : null;
};
