import { NavItem as NavItemType } from '../types';
import SFSymbol from '../../../assets/icons/SFSymbol';
import { iconColors } from '../../../constants/style';

const tabs: NavItemType[] = [
	{
		icon: (
			<SFSymbol
				color={iconColors.labelTertiary}
				name={'tray.fill'}
				className="!w-6 !h-6 text-primary-11"
			/>
		),
		value: 'all',
		label: '📥 All',
		shortcut: '1',
	},
	{
		icon: (
			<SFSymbol
				color={iconColors.labelTertiary}
				name={'sun.max'}
				className="!w-6 !h-6 text-primary-11"
			/>
		),
		value: 'today',
		label: '🗓️ Today',
		shortcut: '2',
	},
	{
		icon: (
			<SFSymbol
				color={iconColors.labelTertiary}
				name={'calendar.badge.clock'}
				className="!w-6 !h-6 text-primary-11"
			/>
		),
		value: 'complete',
		label: '✅ Complete',
		shortcut: '3',
	},
];

export default tabs;
