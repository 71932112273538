import { ReactNode, createContext, useEffect, useState } from 'react';
import { _SnackTodo } from '../lib/core/types';
import supabase from '../lib/core/supabase';
import { useUser } from './AuthContext';
import TodoBackend, { TodoEngine } from '../features/todo/classes';

type TodoContextType = {
	todos: _SnackTodo[] | undefined;
	setTodos: (todos: _SnackTodo[]) => void;
	addTodo: (todo: _SnackTodo) => void;
	updateTodo: (todo: _SnackTodo) => void;
	deleteTodo: (id: string) => void;
};

const TodoContext = createContext<TodoContextType | undefined>(undefined);

const todoEngine = new TodoEngine(supabase, 'todos');
export const todoBackend = new TodoBackend(todoEngine);

const TodoContextProvider = ({ children }: { children: ReactNode }) => {
	const [todos, setTodos] = useState<_SnackTodo[] | undefined>([]);
	const { user } = useUser();
	const [isLoading, setIsLoading] = useState(true);

	const fetchTodos = async () => {
		const { data } = await supabase.from('todos').select('*');
		setTodos(data);
	};

	useEffect(() => {
		todoBackend.setTodos(todos);
	}, [todos]);

	useEffect(() => {
		fetchTodos();
	}, []);

	const addTodo = async (
		todo: Pick<
			_SnackTodo,
			'_when' | 'title' | 'description' | 'id' | 'is_complete' | 'id'
		>,
	) => {
		{
			await supabase.from('todos').insert({
				title: todo.title,
				description: todo.description,
				_when: todo._when,
				is_complete: todo.is_complete,
				user_id: user.id,
			});
		}
	};

	const updateTodo = async (todo: _SnackTodo) => {
		await supabase
			.from('todos')
			.update({
				title: todo.title,
				description: todo.description,
				_when: todo._when,
				is_complete: todo.is_complete,
			})
			.match({ id: todo.id });
	};

	const deleteTodo = async (id: string) => {
		await supabase
			.from('todos')
			.delete()
			.match({ id: parseInt(id) });
		setTodos((prevTodos) => {
			if (prevTodos) {
				return prevTodos.filter((t) => parseInt(t.id) !== parseInt(id));
			}
		});
	};

	useEffect(() => {
		const subscription = supabase
			.channel(`table_db_changes`)
			.on(
				'postgres_changes',
				{
					event: '*',
					schema: 'public',
					table: 'todos',
					filter: `user_id=eq.${user?.id}`,
				},
				(payload) => {
					const todosCopy = [...todos];

					const type = payload.eventType;
					const newRecord: _SnackTodo = payload.new as _SnackTodo;
					const oldRecord = payload.old;

					console.log({ payload });

					if (type === 'INSERT') {
						setTodos([newRecord, ...todosCopy]);
					}

					if (type === 'UPDATE') {
						const idx = todosCopy.findIndex((todo) => todo.id === newRecord.id);
						todosCopy[idx] = newRecord;
						setTodos(todosCopy);
					}

					if (type === 'DELETE') {
						const idx = todosCopy.findIndex((todo) => todo.id === newRecord.id);
						todosCopy.splice(idx, 1);
						setTodos(todosCopy);
					}
				},
			)
			.subscribe();

		return () => {
			subscription.unsubscribe();
		};
	}, [user, todos]);

	return (
		<TodoContext.Provider
			value={{
				todos,
				setTodos,
				addTodo,
				updateTodo,
				deleteTodo,
			}}>
			{children}
		</TodoContext.Provider>
	);
};

export { TodoContext };

export default TodoContextProvider;
