import React from 'react';
import ReactDOM from 'react-dom/client';
import SnackRouter from './router';
import { ClerkProvider } from '@clerk/clerk-react';

const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

console.log({ PUBLISHABLE_KEY });

if (!PUBLISHABLE_KEY) {
	throw new Error('Missing Publishable Key');
}

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);
root.render(
	<React.StrictMode>
		<SnackRouter />
	</React.StrictMode>,
);
