import { format, isEqual, startOfDay, startOfToday } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { cn } from '../../../../lib/functions/utils';
import SFSymbol from '../../../../assets/icons/SFSymbol';
import { iconColors } from '../../../../constants/style';
import { _SnackTodo } from '../../../../lib/core/types';
import Button from '../../../../components/ui/button';
import CalendarIcon from '../../../../assets/icons/CalendarIcon';
import PostItNoteIcon from '../../../../assets/icons/PostItNoteIcon';
import useTodos from '../../../../lib/hooks/useTodos';
import { TrashIcon } from '@heroicons/react/20/solid';

export type DefaultTodoListItemProps = {
	onCheck: (e) => void;
	title: string;
	complete?: boolean;
	_when: Date;
	subtodos?: {
		complete: boolean;
	}[];
	description?: string;
	id: string;
	inserted_at?: Date;
};

const Reminder = () => (
	<button>
		<SFSymbol
			name={'bell'}
			color={iconColors.labelTertiary}
		/>
	</button>
);

const Billable = () => (
	<button>
		<SFSymbol
			name={'dollarsign.circle'}
			color={iconColors.labelTertiary}
		/>
	</button>
);

const When = ({ deadline }) => (
	<button
		style={{ color: iconColors.primary }}
		className={cn('rounded-lg px-2 py-1')}>
		<SFSymbol
			name={'calendar'}
			color={deadline ? iconColors.primary : iconColors.labelTertiary}
		/>
		{deadline && format(deadline, 'MMM dd')}
	</button>
);

const items = [Reminder, When, Billable];

export default function DefaultTodoListItem(
	props: DefaultTodoListItemProps & _SnackTodo,
) {
	return (
		<div className="flex-1 h-full group relative">
			<div className="flex items-center w-full gap-2">
				<input
					className="flex-shrink-0 rounded-xl relative z-1"
					type="checkbox"
					onChange={props.onCheck}
					checked={props.is_complete}
				/>

				<div className="flex items-center flex-1 space-x-2 flex-nowrap gap-4">
					<motion.div className={'w-fit'}>
						<p
							className={cn(
								'line-clamp-1 overflow-hidden text-ellipsis max-w-full transition-all paragraph',
								'group-hover:text-surface-12 dark:group-hover:text-white text-zinc-600 dark:text-surface-6',
								isEqual(
									startOfToday(),
									startOfDay(props._when || new Date(props.inserted_at)),
								) && 'text-zinc-900 dark:text-white',
								props.is_complete && 'line-through text-zinc-500',
							)}>
							{props.title}
						</p>
					</motion.div>
					<ShowAmenities
						description={props.description}
						subtodos={[]}
					/>
				</div>
			</div>
			<HoverActions
				id={props.id}
				_when={props._when}
				is_complete={props.is_complete}
			/>
		</div>
	);
}

const ShowAmenities = (props: {
	description: string;
	subtodos: Array<any>;
}) => (
	<>
		{props.description && <PostItNoteIcon className="w-4 h-4 text-zinc-400" />}
		{props.subtodos.length !== 0 && (
			<div className="flex items-center small gap-1 text-zinc-400">
				<SFSymbol
					name={'checklist'}
					className={'w-5 h-5'}
					color={iconColors.gray}
				/>
				<span className="dark:text-surface-8 text-number font-medium">
					{props.subtodos.length}
				</span>
			</div>
		)}
	</>
);

const HoverActions = (props: {
	id: string | number;
	_when: Date | undefined;
	is_complete: boolean;
}) => {
	const { deleteTodo } = useTodos();
	const onDelete = (e) => {
		e.stopPropagation();
		deleteTodo(props.id.toString());
	};
	return (
		<motion.div className="hidden group-hover:flex absolute top-0 right-0 h-full items-center gap-2">
			<Button
				onClick={onDelete}
				className="p-2 bg-white hover:bg-white border text-red-500 hover:text-red-600 shadow-sm">
				<TrashIcon className="w-4 h-4" />
			</Button>
		</motion.div>
	);
};
