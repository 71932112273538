import { motion } from 'framer-motion';
import SFSymbol from '../../../assets/icons/SFSymbol';
import { iconColors } from '../../../constants/style';
const Toast = ({
	text,
	description,
	index,
	scaleDownFactor,
}: {
	text: string;
	description?: string;
	id: string;
	index: number;
	scaleDownFactor?: number;
}) => {
	return (
		<motion.div
			style={{
				position: 'fixed',
				bottom: 10,
				right: 10,
				zIndex: 100 * (index + 1),
			}}
			initial={{
				opacity: 0,
				scale: 0,
			}}
			animate={{
				opacity: 1,
				scale: scaleDownFactor ? 1 - scaleDownFactor * 0.1 : 1,
				bottom: 10 + index * 20,
				transition: {
					delay: index * 0.1,
				},
			}}
			exit={{
				opacity: 0,
				scale: 0,
			}}>
			<div className="p-4 w-96 rounded-2xl bg-white shadow-card flex gap-4 items-start">
				<SFSymbol
					name={'checklist'}
					color={iconColors.primary}
				/>
				<div>
					<p className={'text-surface-12 font-medium'}>{text}</p>
					<p className={'text-surface-11 line-clamp-2 text-sm'}>
						{description}
					</p>
				</div>
			</div>
		</motion.div>
	);
};

export default Toast;
