import tabs from '../tabs';
import { useEffect, useMemo, useRef, useState } from 'react';
import DockItem from './DockItem';
import DockItemsDropdown from './DockItemsDropdown';
import SFSymbol from '../../../../assets/icons/SFSymbol';
import { AnimatePresence, motion } from 'framer-motion';

const Dock = () => {
	const folders = [];
	const [visibleItemsCount, setVisibleItemsCount] = useState<number>(0);
	const containerRef = useRef<HTMLDivElement>(null);
	const moreButtonRef = useRef<HTMLButtonElement>(null);
	const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);

	const tabsWithFolders = useMemo(
		() =>
			(tabs as any).concat(
				folders.map((folder) => ({
					label: folder.name,
					value: folder.name,
					isFolder: true,
					icon: <SFSymbol name="folder.fill" />,
				})),
			),
		[folders],
	);

	useEffect(() => {
		const calculateVisibleItems = () => {
			// Ensure this runs after the DOM updates
			requestAnimationFrame(() => {
				const containerWidth = containerRef.current?.offsetWidth ?? 0;
				const moreButtonWidth = moreButtonRef.current?.offsetWidth ?? 0;
				// Take into account the width for the moreButton + a buffer
				let cumulativeWidth = moreButtonWidth + 8;
				let itemCount = 0;

				tabRefs.current.forEach((tab) => {
					if (tab) {
						const tabWidth = tab.offsetWidth;
						if (
							cumulativeWidth + tabWidth <=
							containerWidth - moreButtonWidth
						) {
							cumulativeWidth += tabWidth;
							itemCount++;
						}
					}
				});

				setVisibleItemsCount(itemCount);
			});
		};

		// Call calculateVisibleItems initially and on resize
		calculateVisibleItems();

		const resizeObserver = new ResizeObserver(() => {
			calculateVisibleItems();
		});

		if (containerRef.current) {
			resizeObserver.observe(containerRef.current);
		}

		return () => {
			resizeObserver.disconnect();
		};
	}, [tabsWithFolders]);

	return (
		<div
			ref={containerRef}
			className={
				'flex items-center gap-2 drag py-3 px-0 flex-shrink-0 overflow-x-hidden w-auto flex-1'
			}>
			<div className="w-full left-0 z-0 pointer-events-none sr-only">
				{tabsWithFolders.map((tab, index) => (
					<DockItem
						ref={(el) => (tabRefs.current[index] = el)} // Assign ref
						key={tab.label}
						tab={tab}
						index={index}
					/>
				))}
			</div>
			<AnimatePresence>
				{tabsWithFolders.slice(0, visibleItemsCount).map((tab, index) => (
					<DockItem
						ref={(el) => {}} // Assign ref
						key={tab.label}
						tab={tab}
						index={index}
					/>
				))}
				{visibleItemsCount < tabsWithFolders.length && (
					<DockItemsDropdown folders={folders}>
						<motion.button
							layout
							ref={moreButtonRef}
							transition={{
								type: 'tween',
								duration: 0.1,
							}}
							className="py-1 no-drag relative z-20 rounded-xl h-full text-zinc-500 tracking-tight hover:text-surface-12 dark:hover:text-white gap-2 w-fit font-medium hover:bg-zinc-400/20 dark:text-zinc-500 px-2"
							onClick={() => {
								/* logic to show more items */
							}}>
							{tabsWithFolders.length - visibleItemsCount} more
						</motion.button>
					</DockItemsDropdown>
				)}
			</AnimatePresence>
		</div>
	);
};

export default Dock;
