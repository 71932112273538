import { MemoryRouter, Route, Routes } from 'react-router-dom';
import GetStarted from '../features/onboarding';
import SnackApplicationProvider from './App';
import { PageHeader } from '../components/navigation';
import TodoRoute from '../features/todo/pages/inbox/[query]';

const SnackRouter = () => {
	return (
		<div className="flex flex-col w-screen h-screen bg-surface-2 dark:bg-[#121212]">
			<SnackApplicationProvider>
				<MemoryRouter basename="/">
					<div
						className={
							'flex flex-col h-full px-4 container mx-auto overflow-y-auto'
						}>
						<PageHeader options={{ create: true }} />

						<Routes>
							<Route
								index
								path="/"
								element={<GetStarted />}
							/>
							<Route
								path="/home"
								element={<TodoRoute />}
							/>
							<Route
								path="/help"
								element={<TodoRoute />}
							/>
						</Routes>
					</div>
				</MemoryRouter>
			</SnackApplicationProvider>
		</div>
	);
};

export default SnackRouter;
