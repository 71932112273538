import {
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { cn, stopAllPropagation } from '../../../lib/functions/utils';
import { CommandContext } from '../../../context/CommandContext';
import { motion } from 'framer-motion';
import SFSymbol from '../../../assets/icons/SFSymbol';
import { useKeyboardShortcuts } from '../../../context/KbdNavigationContext';
import Search from './search';
import { Keys, metaKey } from '../../ui/typography/Kbd';
import DropdownMenu from '../../ui/dropdown-menu';
import {
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu';
import { iconColors } from '../../../constants/style';
import Dock from './dock';
import Button from '../../ui/button';
import { FolderOpenIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import TodoIcon from '../../../assets/icons/TodoIcon';
import { useUser } from '../../../context/AuthContext';
import useTodos from '../../../lib/hooks/useTodos';
import ProfileBtn from './profile';

type ContextMenuOptions = {
	search?: boolean;
	settings?: boolean;
	help?: boolean;
	listingStyle?: boolean;
	share?: boolean;
	sort?: boolean;
	create?: boolean;
	back?: boolean;
	more?: boolean;
};

export enum PageType {
	Project,
	Todo,
	Generic,
}

export type PageHeader = {
	children?: ReactNode;
	actions?: ReactNode;
	pageType?: PageType;
	title?: ReactNode;
	projectId?: string;
	options?: ContextMenuOptions;
};

const PageHeader = (props: PageHeader) => {
	const { user } = useUser();
	const { todos } = useTodos();
	return (
		<>
			<div className="space-y-1 py-2 pt-16 mb-4 flex flex-col-reverse gap-4 md:flex-row justify-between">
				<div>
					<h1 className="text-3xl font-semibold tracking-tight text-zinc-900">
						Good morning, {user?.displayName?.split(' ')[0]}
					</h1>
					<p>Its Monday, 24 May - {todos?.length} tasks</p>
				</div>
				<div>
					<ProfileBtn />
				</div>
			</div>
			<motion.nav
				style={{
					transformOrigin: 'top',
				}}
				className={cn(
					'-mx-2 h-[3rem] sticky top-0 bg-gradient-to-b from-zinc-50 via-zinc-50 to-zinc-50/90 z-30 mb-4 border-b border-zinc-400/10',
				)}>
				<motion.div
					className={'flex items-center justify-center space-x-2 h-full'}>
					<Dock />

					{props.actions && (
						<div className="flex gap-2 items-center">{props.actions}</div>
					)}
					<div className={'flex justify-end items-center gap-2'}>
						<CreateSomethingDropdown>
							<Button
								className={
									'text-sky-500 hover:text-sky-600 dark:hover:text-sky-500 rounded-xl p-0 h-auto !bg-transparent'
								}>
								<PlusCircleIcon className="w-8 h-8" />
							</Button>
						</CreateSomethingDropdown>
					</div>
				</motion.div>
			</motion.nav>
		</>
	);
};

const CreateSomethingDropdown = (props: { children: ReactNode }) => {
	const { openCreateTodo } = useContext(CommandContext);

	const onOpenAddTodoModal = () => {
		openCreateTodo('');
	};

	const addTodoListener = useCallback((evt: KeyboardEvent) => {
		// If user presses shift + enter in title field, lets add a description field
		if (evt.metaKey) {
			stopAllPropagation(evt);
			onOpenAddTodoModal();
			return;
		}
	}, []);

	const listeners = useMemo(
		() => [
			{
				key: 'n',
				callback: addTodoListener,
				auxillary: [Keys.Meta],
				label: 'Add a todo',
			},
		],
		[addTodoListener],
	);

	useKeyboardShortcuts(listeners);

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>{props.children}</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56 bg-white dark:bg-zinc-800 rounded-xl p-1 z-30 relative shadow-card">
				<DropdownMenuItem
					onClick={onOpenAddTodoModal}
					className={
						'flex items-center gap-2 p-2 hover:bg-zinc-400/20 rounded-xl'
					}>
					<TodoIcon className="w-6 h-6" />
					<span className={'flex-1'}>Todo</span>
					<span className={'!text-surface-10'}>{metaKey.mac} N</span>
				</DropdownMenuItem>
				<DropdownMenuItem
					className={
						'flex items-center gap-2 hover:bg-zinc-400/20 rounded-xl p-2'
					}>
					<FolderOpenIcon className="w-6 h-6" />
					<span className={'flex-1'}>Folder</span>
					<span className={'!text-surface-10'}>{metaKey.mac} G</span>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default PageHeader;
