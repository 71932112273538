import Dropdown from '../../../../../components/ui/dropdown-menu';
import {
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuShortcut,
	ContextMenuTrigger,
	ContextMenu,
	ContextMenuSeparator,
	ContextMenuSubContent,
	ContextMenuSub,
	ContextMenuSubTrigger,
} from '../../../../../components/ui/context-menu';
import { ReactNode } from 'react';
import Kbd, {
	Keys,
	metaKey,
} from '../../../../../components/ui/typography/Kbd';
import SFSymbol from '../../../../../assets/icons/SFSymbol';
import { iconColors } from '../../../../../constants/style';

export type ContextMenuProps = {
	children: ReactNode;
};

type TodoItemContextMenuProps = {
	actions: {
		onScheduler: () => void;
	};
};

export default function TodoItemContextMenu(
	props: ContextMenuProps & TodoItemContextMenuProps,
) {
	return (
		<ContextMenu>
			<ContextMenuTrigger>{props.children}</ContextMenuTrigger>
			<ContextMenuContent className="w-80">
				<ContextMenuItem>
					<SFSymbol
						name={'app.badge.checkmark.fill'}
						className={'mr-2'}
					/>
					Mark as complete
					<ContextMenuShortcut>
						{metaKey.mac} {Keys.Shift} {Keys.Enter}
					</ContextMenuShortcut>
				</ContextMenuItem>
				<ContextMenuSub>
					<ContextMenuSubTrigger>
						<SFSymbol
							name={'circle.dotted.circle'}
							className={'mr-2'}
						/>
						Change status
					</ContextMenuSubTrigger>
					<ContextMenuSubContent className="w-64">
						<ContextMenuItem>
							<SFSymbol
								name={'circle.dotted'}
								className={'mr-2'}
							/>
							Todo
							<ContextMenuShortcut>
								{metaKey.mac} {Keys.Shift} {Keys.Backspace}
							</ContextMenuShortcut>
						</ContextMenuItem>
						<ContextMenuItem>
							<SFSymbol
								name={'circle'}
								className={'mr-2'}
							/>
							In progress
							<ContextMenuShortcut>{metaKey.mac} L</ContextMenuShortcut>
						</ContextMenuItem>
						<ContextMenuItem>
							<SFSymbol
								name={'checkmark.circle.fill'}
								className={'mr-2'}
							/>
							Complete
							<ContextMenuShortcut>
								{metaKey.mac} {Keys.Shift} {Keys.Enter}
							</ContextMenuShortcut>
						</ContextMenuItem>
						<ContextMenuItem>
							<SFSymbol
								name={'exclamationmark.circle.fill'}
								className={'mr-2'}
								color={iconColors.danger}
							/>
							Blocked
							<ContextMenuShortcut>{metaKey.mac} X</ContextMenuShortcut>
						</ContextMenuItem>
					</ContextMenuSubContent>
				</ContextMenuSub>
				<ContextMenuSeparator />
				<ContextMenuItem onClick={props.actions.onScheduler}>
					<SFSymbol
						name={'calendar.badge.clock'}
						className={'mr-2'}
					/>
					Reschedule
					<ContextMenuShortcut>{metaKey.mac} W</ContextMenuShortcut>
				</ContextMenuItem>
				<ContextMenuItem>
					<SFSymbol
						name={'pin.fill'}
						className={'mr-2'}
					/>
					Pin
					<ContextMenuShortcut>{metaKey.mac} P</ContextMenuShortcut>
				</ContextMenuItem>
				<ContextMenuItem>
					<SFSymbol
						name={'square.filled.on.square'}
						className={'mr-2'}
					/>
					Duplicate
					<ContextMenuShortcut>{metaKey.mac} D</ContextMenuShortcut>
				</ContextMenuItem>
				<ContextMenuSeparator />
				<ContextMenuItem style={{ color: iconColors.danger }}>
					<SFSymbol
						name={'trash.fill'}
						className={'mr-2'}
						color={iconColors.danger}
					/>
					Delete
					<ContextMenuShortcut style={{ color: iconColors.danger }}>
						{metaKey.mac} -
					</ContextMenuShortcut>
				</ContextMenuItem>
			</ContextMenuContent>
		</ContextMenu>
	);
}
