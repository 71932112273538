import {
	DndContext,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import clsx from 'clsx';
import { ReactNode } from 'react';

export default function ApplicationContainer(props: {
	hasCalendar?: boolean;
	children: ReactNode;
}) {
	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8,
			},
		}),
	);

	const onDragEnd = (event) => {};
	return (
		<DndContext
			autoScroll
			onDragEnd={onDragEnd}
			sensors={sensors}>
			{props.children}
		</DndContext>
	);
}
