import {
	MouseEvent,
	MouseEventHandler,
	ReactNode,
	useCallback,
	useMemo,
} from 'react';
import useToggle from '../../../lib/hooks/useToggle';
import { motion } from 'framer-motion';

import { cn } from '../../../lib/functions/utils';
import DefaultTodoListItem from './list-item/DefaultTaskListItem';
import DetailedTodoListItem from './list-item/DetailedTaskListItem';
import { useKeyboardShortcuts } from '../../../context/KbdNavigationContext';
import TodoItemContextMenu from './list-item/components/ContextMenuProps';
import { _SnackTodo } from '../../../lib/core/types';
import useTodos from '../../../lib/hooks/useTodos';
import Todo from '../classes/todo';
import { todoBackend } from '../../../context/TodosContext';

export enum TodoListItemView {
	Grid = 'Grid',
	List = 'List',
	Table = 'Table',
}

export type TodoListItemProps = _SnackTodo & {
	icon?: ReactNode;
	view?: TodoListItemView;
	isSelected?: boolean;
	selectedTodo?: string;
	idx: number;
	onExpandTodo: () => void;
	onCollapseTodo?: () => void;
	onSelectTodo?: (isFocused: boolean) => void;
	isDragging?: boolean;
};

export default function TodoListItem(props: TodoListItemProps) {
	const [isSchedulerModalOpen, toggleSchedulerModal] = useToggle(false);
	const [isChecked, toggle] = useToggle(props.is_complete);
	const { updateTodo } = useTodos();

	const todo = useMemo(() => {
		return new Todo(
			{
				description: props.description,
				id: Number(props.id),
				_when: props._when,
				is_complete: props.is_complete,
				inserted_at: props.inserted_at,
				title: props.title,
			},
			todoBackend,
		);
	}, [
		props.description,
		props.id,
		props._when,
		props.is_complete,
		props.inserted_at,
		props.title,
	]);

	const deadline = useMemo(
		() => props._when && new Date(props._when),
		[props._when],
	);

	const onExpandTodo = (e: MouseEvent<HTMLDivElement>) => {
		// only trigger if the click wasnt on input[type="checkbox"]
		if ((e.target as HTMLInputElement).type !== 'checkbox') {
			props.onExpandTodo();
			// Because we want to stop event bubbling
			e.stopPropagation();
			e.preventDefault();
		}
	};

	const onCheck: MouseEventHandler<HTMLInputElement> = (e) => {
		e.preventDefault();
		e.stopPropagation();
		toggle();

		updateTodo({
			id: props.id,
			title: props.title,
			inserted_at: props.inserted_at,
			_when: props._when,
			description: props.description,
			is_complete: !isChecked,
		});

		return;
	};

	const collapseListener = useCallback((e: KeyboardEvent) => {
		props.onCollapseTodo && props.onCollapseTodo();
	}, []);

	const listeners = useMemo(
		() =>
			props.isSelected
				? [
						{
							key: 'Escape',
							callback: collapseListener,
							label: 'Collapse',
							auxillary: [],
						},
				  ]
				: [],
		[props.isSelected],
	);

	useKeyboardShortcuts(listeners);

	return (
		<motion.div
			layout="preserve-aspect"
			layoutId={`root-todo-${props.id}`}
			variants={{
				hidden: {
					opacity: 0,
				},
				visible: (i) => ({
					opacity: 1,
					transition: {
						delay: i * 0.05,
						type: 'spring',
						stiffness: 2000,
						damping: 50,
					},
				}),
			}}
			custom={props.idx}
			initial="hidden"
			animate="visible"
			onClick={onExpandTodo}
			className={cn(
				'relative rounded-xl z-0 h-fit group border-zinc-400/10 dark:border-zinc-400/10 px-3',
				props.isSelected && 'bg-white dark:bg-zinc-800/90 shadow my-4 border',
				!props.isSelected && 'flex-1 h-full  py-4',
				!props.isSelected &&
					!props.isDragging &&
					'hover:bg-zinc-400/20 dark:hover:bg-zinc-400/10',
			)}>
			<TodoItemContextMenu actions={{ onScheduler: toggleSchedulerModal }}>
				{(!props.view || props.view === TodoListItemView.List) && (
					<>
						{!props.isSelected && (
							<DefaultTodoListItem
								{...props}
								is_complete={isChecked}
								_when={deadline}
								onCheck={onCheck}
							/>
						)}
					</>
				)}
				{props.isSelected && (
					<DetailedTodoListItem
						{...props}
						is_complete={isChecked}
						subtodos={[]}
						_when={deadline}
						onCollapse={props.onCollapseTodo}
						onCheck={onCheck}
						isDragging={props.isDragging}
						todo={todo}
					/>
				)}
			</TodoItemContextMenu>
		</motion.div>
	);
}
