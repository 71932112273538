import React from 'react';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import clsx from 'clsx';

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
	React.ElementRef<typeof TooltipPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
	<TooltipPrimitive.Content
		ref={ref}
		sideOffset={sideOffset}
		avoidCollisions
		className={clsx(
			'z-50 overflow-hidden rounded-md bg-surface-12 px-3 py-1.5 text-sm text-white shadow-md',
			className,
		)}
		{...props}
	/>
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };

export default function SnackTooltip(props: { children: React.ReactNode }) {
	return (
		<TooltipProvider>
			<Tooltip>{props.children}</Tooltip>
		</TooltipProvider>
	);
}
