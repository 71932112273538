import { useContext, useMemo } from 'react';

import { isEqual, startOfDay, startOfToday } from 'date-fns';
import { DateContext } from '../../../../../context/DateContext';
import useTodos from '../../../../../lib/hooks/useTodos';

const useDockItemTodoCounter = (tab: {
	href: string;
	label: string;
	value: string;
	isFolder?: boolean;
}) => {
	const { todos } = useTodos();
	const folders = [];
	const now = useContext(DateContext);

	const todosForItem = useMemo(() => {
		if (!tab.isFolder) {
			switch (tab.value) {
				case 'inbox':
					return todos.filter((item) => !item.is_complete);
				case 'today':
					return todos.filter(
						(item) =>
							isEqual(
								startOfDay(new Date(item._when ?? item.inserted_at)),
								startOfToday(),
							) && !item.is_complete,
					);
				case 'later':
					return todos.filter(
						(item) =>
							item._when &&
							new Date(item._when).getTime() > new Date().getTime() &&
							!item.is_complete,
					);
				case 'complete':
					return todos.filter((item) => item.is_complete);
				default:
					return todos.filter((item) => !item.is_complete);
			}
		}

		const folderTodos = folders.filter((folder) => folder.id === tab.value);

		return todos.filter((thisTodo) => {
			return !!folderTodos.find(
				(todoInFolder) => todoInFolder.id === thisTodo.id,
			);
		});
	}, [todos, now]);

	return todosForItem.length;
};

export default useDockItemTodoCounter;
