import { useCallback, useEffect, useMemo, useState } from 'react';
import { useKeyboardListeners } from '../../context/KbdNavigationContext';
import { _SnackTodo } from '../../lib/core/types';

export const useTodoPageLayoutNavigation = (
	todos: _SnackTodo[],
	selectedTodo: string | null,
	setSelectedTodo: (id: string) => void,
) => {
	const [siblings, setSiblings] = useState([null, null]);
	const { registerListeners, unregisterListeners } = useKeyboardListeners();

	const onNext = () => {
		if (siblings[1]) {
			setSelectedTodo(siblings[1]);
		} else {
			const nextId = todos[0].id;
			setSelectedTodo(nextId);
		}
	};

	const onPrev = () => {
		if (siblings[0]) {
			setSelectedTodo(siblings[0]);
		} else {
			const prevId = todos[todos.length - 1].id;
			setSelectedTodo(prevId);
		}
	};

	const onNextCallback = useCallback(
		(event: KeyboardEvent) => {
			onNext();
		},
		[siblings], // Added siblings to dependencies
	);

	const onPrevCallback = useCallback(
		(event: KeyboardEvent) => {
			onPrev();
		},
		[siblings], // Added siblings to dependencies
	);

	const listeners = useMemo(
		() => [
			{
				key: 'ArrowDown',
				callback: onNextCallback,
			},
			{
				key: 'ArrowUp',
				callback: onPrevCallback,
			},
		],
		[onNextCallback, onPrevCallback],
	);

	useEffect(() => {
		if (selectedTodo) {
			registerListeners(listeners);
			return () => unregisterListeners(listeners); // Unregister listeners on cleanup
		}
	}, [selectedTodo, listeners, registerListeners]);

	useEffect(() => {
		const index = todos.findIndex((todo) => todo.id === selectedTodo);
		const prevIndex = index > 0 ? index - 1 : -1;
		const nextIndex = index < todos.length - 1 ? index + 1 : -1;
		const prevId = prevIndex !== -1 ? todos[prevIndex].id : null;
		const nextId = nextIndex !== -1 ? todos[nextIndex].id : null;

		setSiblings([prevId, nextId]);
	}, [selectedTodo]);
};
