import { add } from 'date-fns';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const remToPx = (rem) => {
	return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const formatTime = (time: string | Date) =>
	Intl.DateTimeFormat('en-US', {
		hour: '2-digit',
		minute: '2-digit',
		hourCycle: 'h23',
	}).format(new Date(time));

export const getRandomColorForEvent = () => 'purple';

export const stopAllPropagation = (evt: KeyboardEvent) => {
	evt.preventDefault();
	evt.stopPropagation();
	evt.cancelBubble = true;
	evt.stopImmediatePropagation();
};
